import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Seo from 'components/Seo';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import Layout from 'components/Layout';
import { Inner } from 'styles/structure';
import FeaturedPost from '../components/_page/blog/FeaturedPost';
import SubHero from '../components/SubHero';
import PostCard from '../components/_card/Post';
import PostsGrid from '../components/_grid/Posts';
import Pagination from 'components/Pagination';
import NavSelect from 'components/NavSelect';
import smoothScroll from '../utils/smoothScroll';
import titleCase from '../utils/titleCase';
import client from '../prismic-configuration';
import Prismic from '@prismicio/client'
import { withPreview } from 'gatsby-source-prismic'

const Posts = styled.div`
  padding: 4rem 0;
  background-color: ${colors.grey100};

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 7.2rem 0 10.4rem;
  }
`;

const PostsNav = styled.div`
  position: sticky;
  top: 6.4rem;
  z-index: 110;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    top: 10.4rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  height: 6.4rem;
  background-color: ${colors.teal600};

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    justify-content: space-between;
  }
`;

const NavInner = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 136rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

const CategoriesList = styled.ul`
  display: none;
  padding: 0 4rem;
  flex: 1 1 100%;
  
  li {
    display: inline-block;
    
    & + * {
      margin-left: 4.8rem;
    }
  }
  
  @media (min-width: ${dimensions.desktopUp}px) {
    display: block;
  }
`;

const CategoriesLink = styled(Link)`
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.08s ease-in-out;
  
  &.is-selected,
  &:hover {
    color: ${colors.yellow500};
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
  left: 50%;
  transform: translateX(-50%);
    
  div {
    position: absolute;  
    top: 3.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: ${colors.grey500};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
    &:nth-of-type(1) {
      left: 0.8rem;
      animation: ellipsis1 0.6s infinite;  
    }
    
    &:nth-of-type(2) {
      left: 0.8rem;
      animation: ellipsis2 0.6s infinite;  
    }
    
    &:nth-of-type(3) {
      left: 3.2rem;
      animation: ellipsis2 0.6s infinite;  
    }
    
    &:nth-child(4) {
      left: 5.6rem;
      animation: ellipsis3 0.6s infinite;  
    }
  }
  
  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2.4rem, 0);
    }
  }
  
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

const NoResults = styled.div`
  display: block;
  padding: 4rem 0;
  text-align: center;
  color: ${colors.grey800};
  font-size: 2.4rem;
`;

const LIMIT = 12;

class BlogPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      page: 1,
      total: 0,
      posts: [],
      currentPosts: [],
      loadedPostTotal: 0,
      firstPost: 0,
      lastPost: LIMIT,
    }
  }

  async fetchPosts(after) {
    const response = await client.query(
      Prismic.Predicates.at(
        'document.type',
        'post'),
      {
        pageSize : 100,
        after: after,
        orderings : '[my.post.publish_date desc]'
      }
    )

    if (response) {
      const loadedPosts = response.results;

      this.setState({
        loadedPostTotal: this.state.loadedPostTotal + loadedPosts.length,
        posts: this.state.posts.concat(loadedPosts.filter(post => !post.data.unlisted)),
      });

      if (this.state.loadedPostTotal < response.total_results_size) {
        this.fetchPosts(loadedPosts[loadedPosts.length - 1].id);
      } else {
        this.setState({
          loaded: true,
          filteredPosts: this.state.posts,
          currentPosts: this.state.posts.slice(this.state.firstPost, this.state.lastPost),
        });
      }
    }

    return true;
  }

  async setCurrentPosts() {
    await this.setState({
      loaded: false,
    })

    await this.setState({
      currentPosts: this.state.posts.slice(this.state.firstPost, this.state.lastPost),
      loaded: true,
    })
  }

  async paginate(page) {
    const last = page * LIMIT;
    const self = this;

    smoothScroll('#posts', 600);

    await self.setState({
      page: page,
      firstPost: last - LIMIT,
      lastPost: last,
    });

    await this.setCurrentPosts();
  }

  handleSelectChange = (e) => {
    if (e.target.value === 'all') {
      navigate(`/blog`);
    } else {
      navigate(`/blog/${e.target.value}`);
    }
  }

  componentDidMount() {
    this.fetchPosts();
  }

  render() {
    const landing = this.props.data.allPrismicBlog.edges[0].node.data;
    const self = this;

    return (
      <Layout>
        <Seo
          title={landing.social_title || 'Blog'}
          description={landing.social_description}
          image={landing.social_image}
        />

        <SubHero
          heading={landing.hero_heading}
          content={landing.hero_content}
        />

        {landing.featured_post && (
          <FeaturedPost
            rubric={(landing.featured_post.document.data.category && landing.featured_post.document.data.category.document) && landing.featured_post.document.data.category.document.data.title}
            heading={landing.featured_post.document.data.title.raw}
            background={landing.featured_post.document.data.feature_image}
            destination={`/post/${landing.featured_post.document.uid}`}
          />
        )}

        <PostsNav>
          <NavInner>
            <NavLinks>
              <NavSelect
                name='Topics'
                handleSelectChange={self.handleSelectChange}
                options={landing.categories}
                variant='categories'
              />

              <CategoriesList>
                <li>
                  <CategoriesLink to="/blog" className="is-selected">
                    All Topics
                  </CategoriesLink>
                </li>

                {landing.categories.map((category, i) => {
                  if (category.category) {
                    return (
                      <li key={`category-${i}`}>
                        <CategoriesLink
                          to={`/blog/${category.category.document.uid}`}
                        >
                          {category.category.document.data.title}
                        </CategoriesLink>
                      </li>
                    );
                  }

                  return null;
                })}
              </CategoriesList>
            </NavLinks>
          </NavInner>
        </PostsNav>

        <Posts id='posts'>
          <Inner>
            {self.state.currentPosts.length > 0 && (
              <>
                <PostsGrid>
                  {self.state.currentPosts.map((post, i) => {
                    let readableCategory;

                    if (post.data.category && post.data.category.uid) {
                      readableCategory = titleCase(post.data.category.uid.replaceAll('-', ' ').replace('and', '&'));
                      if (readableCategory === 'Ceos') readableCategory = 'CEOs';
                    }

                    return (
                      <li key={i}>
                        <PostCard
                          title={post.data.title[0].text}
                          uid={post.uid}
                          image={post.data.preview_image || post.data.feature_image}
                          category={readableCategory || false}
                        />
                      </li>
                    )
                  })}
                </PostsGrid>

                <Pagination
                  resourcesPerPage={LIMIT}
                  totalResources={self.state.posts.length}
                  paginate={(page) => self.paginate(page)}
                  currentPage={self.state.page}
                />
              </>
            )}

            {self.state.currentPosts.length === 0 && self.state.loaded && (
              <NoResults>No posts were found for this category.</NoResults>
            )}

            {!self.state.loaded && (
              <Loader>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </Loader>
            )}
          </Inner>
        </Posts>
      </Layout>
    )
  }
}

export const query = graphql`
  query PostList {
    allPrismicBlog {
      edges {
        node {
          uid
          data {
            hero_heading {
              raw
            }
            hero_content {
              raw
            }
            featured_post {
              document {
                ... on PrismicPost {
                  uid
                  data {
                    title {
                      raw
                    }
                    deck
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title
                            description
                          }
                        }    
                      }
                    }
                    preview_image {
                      url
                      alt
                    }
                    publish_date
                    feature_image {
                      url
                      alt
                    }
                  }
                }  
              }
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    uid
                    data {
                      title
                      description
                    }
                  }  
                }
              }
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(BlogPage);